module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LeiPass","short_name":"leipass","start_url":"/","background_color":"#151515","theme_color":"#6A00C1","display":"minimal-ui","icon":"src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"590ba041b4bc9007032fb2cb0e763e54"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/codebuild/output/src487189173/src/leipass-frontend/src/intl","languages":["en-IN","hi-IN"],"defaultLanguage":"en-IN","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-169901568-1"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true}},
    }]
